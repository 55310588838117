nav ,  .footer{
    background-color: #004085 !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height: 47px;
    width: 100% !important;
   
}
.active-icon {
  color: rgb(7, 255, 7);
  
}
.selected-member {
  background-color: yellow; 
}

  .navbar .container-fluid{
    margin-top: -13px !important;
    margin-left: 0px;
  }
  

  /* footer */
  .footer{
    z-index: 999;
    color: white;
  }
  .footer a{
    text-decoration: none;
    color: white;
    font-weight: 600;
    font-size: 13px;
  }

/* maidane admin  */
.maidane-bg{
  height: 150%;
  background-color: white;
}
.pageHeading, .create-gat-style h1{
  color: rgb(4, 64, 119) !important;
  font-size: 1.5em !important; 
  margin-top: 10px !important; 
  font-weight: bold !important;
}
.set-team-btn{
  background-color: #001f3f !important;
  border-radius: 25px;
  border: 1px solid #001f3f ;
  padding: 5px 11px 2px 11px;
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: -5px;
  margin-bottom: 5px;
  font-size: 12px;
}

.set-team-btn:hover{
  background-color: white ;
  border: 1px solid #5d6bd3;
  color: #5d6bd3;
}
.card{
  background-color: #d1ecf1;
  border: none;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  margin: 10px 20px !important;
  text-align: left;
}

.maidane-card-head{
  border-bottom: 1px solid transparent;
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 60%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin: -15px;
  padding: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 64, 133);
  color: white;
  line-height: 15px;
  font-size: 15px;

}
.card-inline-elements {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  padding-bottom: 0px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(255, 254, 254, 0.6);
  background-color: transparent !important;
}
.card-inline-elements .card-title{
  font-weight: 700 !important;
  color: white;

}

.live-area{
 margin-top: 10px;
 font-size: 12px;
}
.live-card {
  background-color: #20db4c;
  box-shadow: 0 1px 10px 3px rgba(8, 255, 70, 0.2);
}
.livestart-button{
  background-color: #fa1a1a;
  border-radius: 5px;
  padding: 5px 10px;
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 15px;
}
.liveend-button, .show-finale-btn{
  background-color: green ;
  border-radius: 5px;
  padding: 5px 10px;
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 15px;
  border: 1px solid green;
}

.liveend-button:hover, .show-finale-btn:hover, .livestart-button:hover{
  background-color: white ;
  color: #5d6bd3;
  border: 1px solid #5d6bd3;
}

/* create gat */
.create-gat-style input{
padding: 4px 10px;
border-radius: 5px;
border: 1px solid #1a1a1f;
width: fit-content;
border-bottom-right-radius: 0px;
border-top-right-radius: 0px;
margin-top: 1px;
width: 85%;
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .create-gat-style input {
    width: 350px;
  }
  .create-gat-first-btn button{
    width: 100px !important;
  }
}
.create-gat-first-btn button{
  margin-left: -1px;
  border: 1px solid #5d6bd3;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 4px 1px !important;
  width: 15%;
  background-color: #5d6bd3;
}
.create-gat-first-btn button:hover{
  height: 35px;
}
.btn-secondary{
  color: blue;
  border-radius: 5px;
  margin: 5px;
  border: none;
  background-color: white;
}

.btn-warning{
  background-color: transparent;
    border-radius: 5px;
    margin: 5px;
    border: none;
    color: rgb(179, 179, 3);
  }
  .btn-danger{
    background-color: transparent;
      border-radius: 5px;
      margin: 5px;
      border: none;
      color: #fa1a1a;
    }
.btn-success{
  color: white;
  border: 1px solid green;
  background-color: green;
  padding: 5px 10px 3px 10px;
  border-radius: 5px;
}
.btn-success:hover, .btn-danger:hover, .btn-secondary:hover, .btn-warning:hover{
  background-color: white;
    border-radius: 5px;
    border: none;
    color: #171F49; 
    border: 1px solid #171F49;
  }
  
  .table-responsive {
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    margin-bottom: 20px;
    border-collapse: collapse;
  }
  
  .table th,
  .table td {
    padding: 10px;
    border: 1px solid #dee2e6;
  }
  
  .table th {
    background-color: #e9ecef;
    text-align: center;
  }
  
  @media (max-width: 768px) {

    .btn-warning, .btn-danger, .btn-secondary {
      margin: 2px;
      padding: 5px;
    }
  }
  
  /* maidane user */
.maindan-card{
  background-color: rgb(255, 250, 250);
  color: black;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 9px;
}