.custom-ul {
    width: 35%; /* Adjust the width as needed */
    margin: 0 auto; /* Center the ul */
  }
  
  .custom-li {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Ensure li elements take full width of ul */
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); /* Add shadow */
    border-radius: 5px; /* Add rounded corners */
    margin-bottom: 10px; /* Add space between list items */
  }
  
  .container {
    max-width: 100%; /* Ensure the form container is not too wide */
    margin: 0 auto; /* Center the container */
  }
  /* Responsive adjustments */
@media (max-width: 767px) {
    .custom-ul {
        width: 100%; /* Adjust the width as needed */
        margin: 0 auto; /* Center the ul */
      }
      
  }
  
  .pageHeading{
    color: rgb(4, 64, 119);
    font-size: 2.2em; 
    margin-top: 10px; 
    font-weight: bold;
  }
  .pageHeading2{
    color: green;
    font-size: 22px; 
    font-weight: bold;
    text-decoration: underline;
  }