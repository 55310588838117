/* ProfilePage.css */

.profile-page {
    background: linear-gradient(to bottom right, #bfd6ee, #183f61); /* Gradient from light to darker blue */
}

  .profile-card {
    border-radius: 10px; /* Rounded corners for the card */
    border: 1px solid #d3cece; /* Light border */
  }

  
  .decoration-underline{
    border-bottom: 2px solid rgba(146, 125, 6, 0.616) !important;
  }
  .profile-card .card-body {
    padding: 2rem; /* Increased padding for better spacing */
    background: linear-gradient(to bottom right, #084258, #e1e6ec);
  }
  
  .profile-card .card-title {
    font-size: 1rem; /* Larger font size for titles */
    color: #ffffff; /* Dark color for titles */
  }
  
  .profile-card .card-text {
    font-size: 1.2rem; /* Slightly larger font size for text */
    color: #02274b !important; /* Darker color for text */
  }
  
  .profile-logout-btn {
    background-color: #b10505; /* Primary button color */
    border: none; /* Remove border */
    padding: 0.75rem 1.5rem; /* Padding for button */
    font-size: 1rem; /* Font size for button text */
    border-radius: 5px; /* Rounded corners for the button */
  }
  
  .profile-logout-btn:hover {
    background-color: #720b0b; /* Darker button color on hover */
  }
  