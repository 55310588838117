/* Ensure consistent spacing and typography */
.pageHeading {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: #044077; /* Adjusted color for better readability */
}

.maindan-card {
  background-size: cover;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

/* Adjust card backgrounds and hover effects */
.maindan-card:not(.live-card) {
  text-align: left;
  color: black;
  background-color: #dfe7f0; /* Original color */
}

.maindan-card.live-card {
  text-align: left;
  box-shadow: 0 4px 8px 5px rgba(32, 238, 5, 0.4); /* Adjusted shadow for live cards */
  background-color: #6feb4d !important; /* Adjusted color for live cards */
}

.maindan-card:hover {
  transform: translateY(-5px);
}

.card-body {
  background: hsla(180, 27%, 81%, 0.911);
  padding: 20px;
  border-radius: 8px;
}

.card-inline-elements {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(24, 167, 250);
}

.set-team-btn {
  background-color: #8b4f77;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 3px;
}

.set-team-btn:hover {
  background-color: #d392db; /* Darker shade on hover for contrast */
}

.card-winners {
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.card-winners li {
  margin-right: 20px;
  font-weight: bold;
  text-align: left;
  float: left;
  animation: blink-animation 1s infinite;
}

.card-winners li:last-child {
  display: flex;
  align-items: center;
}

.card-winners li:last-child::after {
  content: '';
  background-size: contain;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}

@keyframes blink-animation {
  0%, 49.9%, 100% {
    color: inherit;
  }
  50% {
    color: red;
  }
}

.live-symbol {
  position: absolute;
  top: 1px;
  left: 5%;
  transform: translateX(-50%);

  /* width: 10px;
  height: 10px;
  background-color: transparent;
  border: 2px solid white; */
  border-radius: 5%;
  text-align: center;
  line-height: 1.6;
  padding: 3px 5px 0px 5px;
  font-size: 9px;
  font-weight: bold;
  animation: blink-animation 1s infinite;
  background-color: red;
  margin-bottom: 5px;
}

.live-circle {
  animation: blink-animation 1s infinite;
  background-color: red;
}

.position {
  font-weight: bold;
  margin-right: 5px;
}
.map-img img{
  border-radius: 5px !important;
}
.map-img:hover{
  cursor: pointer;
  opacity: 0.7;
}


.favorite-icon {
  cursor: pointer;
  font-size: 24px;
  float: right;
}

.remove-favorite-btn {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1px;
}

.remove-favorite-btn:hover {
  background-color: #c82333;
}
.maidanesearch-bar input {
  width: 300px !important;
  right: 0;
  margin-left: auto;
}

.search-bar .form-control {
  border-radius: 0.25rem;
}
