div.create-gat-style {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
  }
  
  .create-gat-first-btn input {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 10px;
  }
  
  .create-gat-first-btn button {
    padding: 10px 20px;
    background-color: #293163;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .table-responsive {
    margin-top: 20px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; 
  }
  
  .table th, .table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
    width: 100%;
  }
  
  .table th {
    background-color: #f2f2f2;
  }
  
  .table td button {
    padding: 3px 8px; /* Adjust padding to make the button smaller */
    background-color: #dbbc3d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px; /* Adjust font size to make the button smaller */
  }
  
  
  .table td button:hover {
    background-color: #072d56;
  }
  .search-bar-container {
    position: absolute;
    right: 10px; /* Adjust right position as needed */
    margin-left: 90%;
  }
  
  .search-bar-container input {
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 250px; /* Adjust width as needed */
  }

  .horizontal-scroll-wrapper {
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .horizontal-scroll-wrapper .table {
    display: inline-block;
  }
  
  .create-gat-style {
    /* Your existing styles */
  }
  
  .create-gat-first-btn {
    /* Your existing styles */
  }
  
  .search-bar-container {
    /* Your existing styles */
  }
  
  .table-responsive {
    /* Your existing styles */
  }
  
  /* Adjust styles for input boxes */
  input[type="text"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    width: 200px;
  }
  
  /* Style for add input button */
  button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  /* Style for icons */
  .fa-pen-to-square,
  .fa-circle-xmark {
    font-size: 22px;
    cursor: pointer;
  }
  
  
  
  