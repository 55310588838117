.App {
  text-align: center;
}
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Devanagari:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
 *{
  font-family: "Noto Serif Devanagari", serif;
 }
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --color-black: #FF000000;
  --color-white: #FFFFFFFF;
  --color-orange: #FF5722;
  --color-scratch: #6BC1C1C1;
  --color-brandcolor: #444C7E;
  --color-secondcolor: #BA5E69B3;
  --color-bgrdark: #171F49;
  --color-layselected: #2D3A83;
  --color-darksecondcolor: #9C5E69B3;
  --color-bro: #D16868;
  --color-crd: #5E5E69B3;
  --color-sssd: #C1C1C1;
  --color-dd: #D8D8D8;
  --color-spinn: #A6171F49;
  --color-color2: #30355A;
  --color-blue: #9FC8E8;
  --color-light: #F1C7BA;
  --color-lightpink: #F4C0D2;
  --color-dark: #B9C2F3;
  --color-red: #EF415A;
}
